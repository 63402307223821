<template>
  <div class="PageBox all">
    <van-nav-bar
            fixed
            :border="false"
            :title="$t('invite[0]')"
            left-arrow
            @click-left="$router.go(-1)"
    />
    <div class="activity">
      <div v-show="UserInfo.vip_level>=3">
        <div style="color: #FC9B4C">{{$t("invite[1]")}}</div>
        <div style="margin: 5px;font-weight: bolder;font-size: 26px">{{idcode}}</div>
        <div style="margin:0 auto;width: 45%;height: 140px">
          <div class="QRCode" id="qrcode"/>
        </div>
        <div style="margin-top: 5px">{{$t("invite[2]")}}</div>
      </div>
    </div>
    <div style="text-align: center;margin-top: 20px" v-show="UserInfo.vip_level>=3">
      <p class="btn" @click="copyCode">{{ $t('invite[3]') }}</p>
    </div>
    <textarea id="inputBox" style="position: absolute;top: 0;left: 0;opacity: 0;z-index: -10;"></textarea>
  </div>
</template>
<script>
  import QRCode from "qrcodejs2";
  import { Toast, Dialog } from "vant";
  import config from "@/config";

  var c;
  export default {
    data() {
      return {
        //show  shows
        chenggong: false, //  领取成功时的弹窗
        shibai: false, // 不能领取时的弹窗
        inviteInfo: {
          sure_receive_money: "",
          item: {
            cover_img: "",
          },
        }, //保存邀请数据

        flag: true, //判断是否领取过
        vip_level: "", //vip等级
        invalid_receive_money: 0,
        award_model: 0, //模式
        agent_type:0,
        showAddress:true,
        inviteUrl:'',
        idcode:null,
        qrcode:null,
      };
    },
    props: ["id", "sign_id"],
    created() {
      this.inviteUrl = config.ApiUrl+"/#/register/"+this.UserInfo.idcode;
      this.idcode = this.UserInfo.idcode;
    },
    mounted() {
      var that = this;
      this.$Model.GetUserInfo(data=>{
        if (data.code==1){
          that.agent_type = data.info['agent_type']
          that.doShow();
        }
      })

    },
    computed: {
      //领取按钮
      receive() {
        if (this.inviteInfo.sure_receive_money == 0) {
          return "sem quantia"; //余额不足
        }
        if (this.inviteInfo.sure_receive_money != 0) {
          return "Receber"; //领取
        } else if (this.flag === false) {
          return "Já recebido hoje"; //今日已领取
        }
      },
      //活动规则
      explain() {
        if (this.inviteInfo) {
          return this.inviteInfo.item.explain;
        } else {
          return "<p></p>";
        }
      },
    },
    filters: {
      只显示小数后两位: function (n) {
        return n.substr(0, n.indexOf(".") + 3);
      },
    },
    methods: {
      //复制码和金额
      copyCode() {
        var copyBox = document.getElementById("inputBox");
        copyBox.value = this.inviteUrl;
        copyBox.select();
        document.execCommand("Copy"); // 执行浏览器复制命令
        this.$Dialog.Toast(this.$t('recharge.label[5]'));
      },
      toWallet() {
        this.$router.push("/user/wallet");
      },
      toPromote() {
        this.$router.push("/user/promote");
      },
      doShow(){
        if (!this.qrcode){
          var codeElement = document.getElementById("qrcode");
          this.qrcode = new QRCode(codeElement, {
            text: this.inviteUrl,
            width: 140,
            height: 140,
            colorDark : "#000000",
            colorLight : "#ffffff",
            correctLevel : QRCode.CorrectLevel.H
          });
          let b = 100;
          // new QRCode(codeElement, this.InitData.setting.service_url);
        }
      },
    },
  };
</script>
<style scoped>
  .all {
    overflow: auto;
    background: url("../../../public/static/images/bg_invite2.png") no-repeat;
    width: 100%;
    height: 100%;
    background-size: contain;
  }
  .PageBox {
    background-color: #FECB8A;
  }

  .van-nav-bar {
    background-color:transparent;
  }
  .van-nav-bar>>>.van-nav-bar__title{
    color:#FFFEFF;
  }
  .van-nav-bar>>>.van-nav-bar__arrow {
    color:#FFFEFF;
  }
  .title {
    color: white;
    height: 46px;
    line-height: 46px;
    font-size: 17px;
    letter-spacing: 1px;
    position: fixed;
    background: #F46926;
    z-index: 99;
    top: 0;
    width: 100%;
    text-align: center;
  }
  .activity {
    width: 75%;
    height: 270px;
    color:#FF8603;
    border-radius: 10px;
    margin: 0 auto;
    margin-top: 305px;
    text-align: center;
    line-height: 25px;
    border-radius: 20px;
    background-color: #FFE3C4;
    font-size: 18px;

  }
  .cover_img {
    width: 100%;
    height: auto;
  }
  .cover_img > img {
    width: 100%;
    height: auto;
    display: block;
  }
  .inviteTit {
    width: 220px;
    height: 125px;
    /* background:pink; */
    position: absolute;
    top: 130px;
    left: 80px;
    padding: 10px 30px;
    text-align: center;
    font-weight: 600;
  }
  .inviteTit > p:first-child {
    color: #fff;
    font-size: 18px;
    line-height: 20px;
  }
  .inviteTit > p:last-child {
    color: rgb(0, 255, 255);
    font-size: 25px;
    line-height: 30px;
  }
  .invitePeople {
    width: 200px;
    height: 52px;
    border-radius: 10px;
    background: linear-gradient(to bottom, rgb(255, 231, 77), rgb(255, 201, 27));
    box-sizing: border-box;
    padding: 4px;
    position: absolute;
    bottom: 45px;
    left: 75px;
    font-size: 24px;
    font-weight: 600;
    line-height: 44px;
    text-align: center;
    color: rgb(255, 57, 91);
  }
  .receivableAmount {
    width: 100%;
    padding: 25px 0;
    height: 230px;
    box-sizing: border-box;
  }
  .receivableAmount-img {
    width: 230px;
    height: 100%;
    margin: 0 auto;
    background: url(../../../public/static/images/30.png) no-repeat center/100%;
    overflow: hidden;
  }
  .QRCode {
    margin: 10px auto;
    height: 100px;
    text-align: center;
    align-items: center;
  }
  .amount {
    margin-top: 55px;
    height: 25px;
    width: 100%;
    text-align: center;
    line-height: 25px;
    font-size: 12px;
    transform: scale(0.95);
    color: rgb(131, 50, 255);
  }
  .moeny {
    width: 100%;
    height: 50px;
    text-align: center;
    line-height: 50px;
    font-size: 30px;
    font-weight: 600;
    color: rgb(255, 57, 91);
  }
  .receiveBtn {
    width: 100%;
    height: 30px;
    line-height: 30px;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    color: rgb(255, 57, 91);
  }
  .rewardList {
    width: 100%;
    height: 70px;
    padding: 15px 0;
  }
  .rewardList > div {
    width: 165px;
    height: 100%;
    line-height: 40px;
    margin: 0 auto;
    color: #000;
    border-radius: 20px;
    background: #fee9d0;
    text-align: center;
    font-size: 16px;
    /* letter-spacing: 2px; */
    font-weight: 600;
    padding: 0 10px;
    box-sizing: content-box;
    color: #333;
  }
  .exposition {
    width: 100%;
    height: auto;
    padding: 0 30px 40px;
  }
  .exposition >>> p {
    color: #313131;
    margin-top: 6px;
  }
  .success {
    width: 300px;
    height: 238px;
    background: linear-gradient(to bottom, rgb(254, 147, 164), rgb(79, 82, 191));
    border-radius: 20px;
  }
  .successImg {
    width: 70px;
    height: 70px;
    position: absolute;
    top: -35px;
    left: 115px;
  }
  .successImg > img {
    width: 100%;
    height: 100%;
  }
  .successContent {
    width: 290px;
    height: 248px;
    background: #fff;
    border-radius: 20px;
    margin: 5px;
    overflow: hidden;
    text-align: center;
    padding: 0 20px;
  }
  .successContent > h2 {
    width: 100%;
    height: 40px;
    line-height: 40px;
    margin-top: 30px;
    font-size: 18px;
    color: rgb(254, 136, 157);
  }
  .successContent > p {
    width: 100%;
    height: auto;
    margin-top: 10px;
    line-height: 30px;
    letter-spacing: 1px;
  }
  .successContent > div {
    width: 100%;
    height: 40px;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
  }
  .successContent > div > div {
    width: 120px;
    height: 100%;
    text-align: center;
    line-height: 40px;
    color: #fff;
    background: rgb(78, 81, 191);
    border-radius: 15px;
  }
  .fail {
    width: 300px;
    height: 258px;
    background: linear-gradient(to bottom, rgb(79, 82, 191), rgb(254, 147, 164));
    border-radius: 20px;
    overflow: hidden;
  }
  .failImg {
    width: 70px;
    height: 70px;
    position: absolute;
    top: -35px;
    left: 115px;
  }
  .failImg > img {
    width: 100%;
    height: 100%;
  }
  .failContent {
    width: 290px;
    height: 248px;
    background: #fff;
    border-radius: 20px;
    margin: 5px;
    overflow: hidden;
    text-align: center;
    padding: 0 20px;
  }
  .failContent > h2 {
    width: 100%;
    height: 40px;
    line-height: 40px;
    margin-top: 30px;
    font-size: 18px;
    color: rgb(254, 136, 157);
  }
  .failContent > p {
    width: 100%;
    height: auto;
    margin-top: 50px;
    line-height: 30px;
    letter-spacing: 1px;
  }
  .failContent > div {
    width: 100%;
    height: 40px;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
  }
  .failContent > div > div {
    width: 120px;
    height: 100%;
    text-align: center;
    line-height: 40px;
    color: #fff;
    background: rgb(78, 81, 191);
    border-radius: 15px;
  }
  .rem {
    font-size: 500px;
  }
  .btn {
    padding: 10px 10px;
    border-radius: 10px;
    background-color: #FE963F;
    color: #FFFEFF;
    font-size: 18px;
    text-align: center;
    margin: 0px 10px 10px;
  }
</style>
